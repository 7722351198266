import React from "react";


import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { AiFillGithub } from "react-icons/ai";
import codeEditor from "../images/codeEditor_pro.png"
import todo from "../images/Todo_pro.png"
import port from "../images/port.png"
import { motion } from "framer-motion";
const Projects = () => {
 
  return (
    <section
      id="#projects"
      className="max-w-7xl py-6 xxl:py-20 mx-auto w-full px-6"
    >
    
      <div className="md:flex flex-col justify-center">
      <motion.h1
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: 50, opacity: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl md:text-5xl font-semibold max-w-min leading-tight"
        >
      
           Projects
       
        </motion.h1>
        <div className="py-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:flex-1">
        <motion.article
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: 100, opacity: 0 }}
            transition={{ duration: 1 }}
            className="flex flex-col gap-4 "
          >
         
            <h1 className="font-semibold text-2xl">Real-time Code Editor</h1>
             
          <div className="flex flex-wrap gap-y-2 gap-x-1 md:justify-between">
            <span className="inline-block bg-blue-600 text-white px-2 py-1 rounded-full text-sm font-semibold">
              React
            </span>
            <span className="inline-block bg-green-700 text-white px-2 py-1 rounded-full text-sm font-semibold">
              Node Js
            </span>
            <span className="inline-block bg-yellow-600 text-white px-2 py-1 rounded-full text-sm font-semibold">
              Express
            </span>
            <span className="inline-block bg-violet-700 text-white px-2 py-1 rounded-full text-sm font-semibold">
              Socket IO
            </span>
            <span className="inline-block bg-pink-800 text-white px-2 py-1 rounded-full text-sm font-semibold">
              GitHub
            </span>
          
          </div>
            
            <div className="rounded-lg p-3 bg-gradient-to-r from-blue-200 to-green-300 cursor-pointer">
              <img
                src={codeEditor}
                alt="code"
                width={200}
                height={200}
                className="w-full h-[200px] rounded-md "
              />
            </div>
            <div className="flex w-full justify-evenly">
              <a
                href="https://github.com/Chandrikatammana2002/RealTime_CodeEditor"
                target="_blank" rel="noreferrer"
                className="flex items-center gap-1 font-semibold hover:bg-black hover:text-white rounded-xl px-3 py-1 transition-all duration-300"
              >
                GitHub <AiFillGithub />
              </a>
              <a
                href="https://real-time-code-editor-brown.vercel.app/"
                rel="noreferrer"
                target="_blank"
                className="flex items-center gap-1 font-semibold hover:bg-black hover:text-white rounded-xl px-3 py-1 transition-all duration-300"
              >
                Deployed Link <GlobeAltIcon className="w-4 h-4" />
              </a>
            </div>
          
        
          </motion.article>
          <motion.article
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: 100, opacity: 0 }}
            transition={{ duration: 1 }}
            className="flex flex-col gap-4"
          >
         
            <h1 className="font-semibold text-2xl">Todo</h1>
            
          <div className="flex flex-wrap gap-y-2 gap-x-1 md:justify-between">
            <span className="inline-block bg-blue-600 text-white px-2 py-1 rounded-full text-sm font-semibold">
              React
            </span>
            <span className="inline-block bg-green-700 text-white px-2 py-1 rounded-full text-sm font-semibold">
              Node Js
            </span>
            <span className="inline-block bg-yellow-600 text-white px-2 py-1 rounded-full text-sm font-semibold">
              Express
            </span>
            <span className="inline-block bg-violet-700 text-white px-2 py-1 rounded-full text-sm font-semibold">
              MongoDB
            </span>
            <span className="inline-block bg-pink-800 text-white px-2 py-1 rounded-full text-sm font-semibold">
              TailwindCSS
            </span>
            
          
          </div>
     
            <div className="rounded-lg p-3 bg-gradient-to-r from-blue-200 to-green-300 cursor-pointer ">
               <img
                src={todo}
                alt="code"
                width={200}
                height={200}
                className="w-full h-[200px] rounded-md"
              />
            </div>
            <div className="flex w-full justify-evenly">
              <a
                href="https://github.com/Chandrikatammana2002/Todos"
                rel="noreferrer"
                target="_blank"
                className="flex items-center gap-1 font-semibold hover:bg-black hover:text-white rounded-xl px-3 py-1 transition-all duration-300"
              >
                GitHub <AiFillGithub />
              </a>
              <a
                href="https://todos-lyart-ten.vercel.app/"
                target="_blank"
                className="flex items-center gap-1 font-semibold hover:bg-black hover:text-white rounded-xl px-3 py-1 transition-all duration-300"
              >
                Deployed Link <GlobeAltIcon className="w-4 h-4" />
              </a>
            
            </div>
        
          </motion.article>
          <motion.article
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: 100, opacity: 0 }}
            transition={{ duration: 1 }}
            className="flex flex-col gap-4"
          >
          
            <h1 className="font-semibold text-2xl">Portfolio</h1>
            <div className="flex flex-wrap gap-y-2 gap-x-1 md:justify-between">
              <span className="inline-block bg-blue-600 text-white px-2 py-1 rounded-full text-sm font-semibold">
                React
              </span>

              <span className="inline-block bg-pink-800 text-white px-2 py-1 rounded-full text-sm font-semibold">
                TailwindCSS
              </span>

              <span className="inline-block bg-yellow-600 text-white px-2 py-1 rounded-full text-sm font-semibold">
                GitHub
              </span>
              <span className="inline-block text-white px-2 py-1 rounded-full text-sm font-semibold">
                 MongoDBMongoose
              </span>
          
          </div>
            
         
            <div className="rounded-lg p-3 bg-gradient-to-r from-blue-200 to-green-300 cursor-pointer">
               <img
                src={port}
                alt="code"
                width={200}
                height={200}
                className="w-full h-[200px] rounded-md"
              />
            </div>
            <div className="flex w-full justify-evenly">
              <a
                href="https://github.com/Chandrikatammana2002/Portfolio"
                target="_blank"
                className="flex items-center gap-1 font-semibold hover:bg-black hover:text-white rounded-xl px-3 py-1 transition-all duration-300"
              >
                GitHub <AiFillGithub />
              </a>
              <a
                href="https://chandu.vercel.app/"
                target="_blank"
                className="flex items-center gap-1 font-semibold hover:bg-black hover:text-white rounded-xl px-3 py-1 transition-all duration-300"
              >
                Deployed Link <GlobeAltIcon className="w-4 h-4" />
              </a>
            </div>
            
         
          </motion.article>
          </div>
      </div>
    </section>
  );
};

export default Projects;
