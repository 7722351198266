import React from "react";
import { motion } from "framer-motion";


import TechWomen from "../images/TechWomen.png"
import { IoIosArrowForward } from "react-icons/io";
import Projects from "../components/Projects";
import Skills from "./Skills";
import Contact from "./Contact";
import {Link} from "react-scroll"

function Hero(){
    return(
        <>
       
        <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 2.5 }}
            id="#home"
        >

            <div className="flex items-center justify-center min-h-screen mt-3">
                <div className="w-full h-[30rem] m-10 bg-gradient-to-r from-yellow-200 to-blue-300 text-black flex-col gap-6 rounded-lg shadow-lg ">
                   <img src={TechWomen} alt="profile" className="w-24 mx-auto mt-11 rounded-full sm:w-32 md:w-48 lg:w-24" />
                   <p className="font-semibold text-2xl mt-10 md:text-4xl lg:text-5xl">
                        Hello, I&apos;m Chandrika
                    </p>
                   
                    <p className="font-medium px-2 md:text-4xl mt-6 lg:mx-auto   text-center md:max-w-xl  leading-tight align-middle">
                        I love building engaging and user-friendly digital experiences.
                    </p>
                    <div className="flex items-center justify-center m-auto text-sm md:text-lg md:gap-6 mt-8">
                        
                        <Link to="#contact"
                            smooth={true}
                           duration={1000}
                            className="bg-black md:w-fit text-white rounded-3xl px-3 md:px-6 py-1 font-medium cursor-pointer">
                               Contact
                        </Link>
                        <div className="flex items-center gap-1 md:gap-2 cursor-pointer hover:bg-white hover:shadow-lg transition-all rounded-2xl px-3 py-1">
                            <a
                                href="https://drive.google.com/file/d/1p2ooesUo-vANI7RwTsGaMit7Xe_zhJwc/view?usp=drivesdk"
                                download={"Chandrika.pdf"}
                                className="font-semibold"
                            >
                                Download Resume
                            </a>
                            <IoIosArrowForward size={17} color="black" />
                        </div>
                    </div>
                </div>
            </div>
            
           
       </motion.div>
        <Projects/>
        <Skills/>
        <Contact/>
       </>

    )
}

export default Hero;