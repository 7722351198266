import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";


function Home(){
    return(
        <div>
           <Navbar/>
           <Hero/>
         
        </div>
    )
}

export default Home;
