import React from "react";
import { motion } from "framer-motion";

const Contact=()=>{

    return (
        <section
          id="#contact"
          className="max-w-7xl py-6 xxl:py-16 mx-auto w-full px-6"
        >
          <motion.h1
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: 50, opacity: 0 }}
            transition={{ duration: 1 }}
            className="text-3xl md:text-5xl font-semibold max-w-xl leading-snug lg:items-center justify-center mx-auto"
          >
           Contact Me
           
          </motion.h1>
          <form action="https://formspree.io/f/mvonydjn" method="POST" className="pr-1 md:w-[70%] mx-auto py-6 md:py-12 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
            <motion.div
              whileInView={{ x: 0, opacity: 1 }}
              initial={{ x: -100, opacity: 0 }}
              transition={{ duration: 1 }}
              className="flex flex-col gap-1"
            >
              <label htmlFor="name" className="font-semibold">
                Your Name
              </label>
              <input
                type="text"
                name="username"
                autoComplete="off"
                required
                className="border border-gray-700 rounded-md px-2 py-1"
              />
            </motion.div>
            <motion.div
              whileInView={{ x: 0, opacity: 1 }}
              initial={{ x: -100, opacity: 0 }}
              transition={{ delay: 0.1, duration: 1 }}
              className="flex flex-col gap-1"
            >
              <label htmlFor="mail" className="font-semibold">
                Your Email
              </label>
              <input
                type="email"
                name="Email"
                required
               
               
                id="mail"
                className="border border-gray-700 rounded-md px-2 py-1"
              />
            </motion.div>
            <motion.div
              whileInView={{ y: 0, opacity: 1 }}
              initial={{ y: 100, opacity: 0 }}
              transition={{ duration: 1 }}
              className="flex flex-col md:col-span-2 gap-1"
            >
              <label htmlFor="message" className="font-semibold">
                Your Message
              </label>
              <textarea
                className="border border-gray-700 rounded-md px-2 py-1"
                name="message"
                
                required
               
                id="message"
                cols={30}
                rows={7}
              ></textarea>
            </motion.div>
            <motion.button
             
              type="submit"
             
              whileInView={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
              className={`bg-[#ABECD6] text-lg font-semibold py-2 rounded-md hover:shadow-md transition-all cursor-pointer disabled:bg-[#c7e5db] disabled:cursor-not-allowed`}
            >
              Send Message
            </motion.button>
          </form>
        </section>
      );

}

export default Contact;